$headerHeight: 53px;

.header {
  position: sticky;
  top: 0;
  padding: 5px 0;
  margin-bottom: 12px;
  background-color: #000;
  z-index: 10;
  height: $headerHeight;

  :global {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.menuButton {
  padding: 5px;
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  margin-left: -5px;
  cursor: pointer;

  img {
    height: 20px;
  }
}

.logo {
  flex: 0 0 200px;
  display: flex;

  img {
    width: 100%;
    height: auto;
  }
}

.qrCode {
  padding: 5px;
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: -5px;

  img {
    height: 40px;
  }
}

.nav {
  position: absolute;
  background-color: #000;
  position: fixed;
  z-index: 9;
  top: $headerHeight;
  bottom: 0;
  left: -100vw;
  padding-left: 100vw;
  right: 0;
  padding-top: 36px;
  transition: all 0.3s ease-in-out;
  transform: rotateY(90deg);
  text-align: center;

  &.active {
    transform: rotateY(0);
  }

  ul {
    list-style-type: none;
    width: 100%;
  }

  a {
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
  }
}