body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
  -webkit-tap-highlight-color:  transparent;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  touch-action: manipulation;
}
* {
  /*touch-action: none !important;*/
  touch-action: manipulation;
}

body * {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}


#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  max-width: 400px;
}