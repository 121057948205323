.footer {
  background-color: #363636;
  color: #fff;
  margin-top: auto;
  padding-top: 24px;
  font-size: 14px;

  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 3px 0;
  }

  ul {
    list-style-type: none;
    
    margin-bottom: 24px;
  }

  li {
    margin-bottom: 2px;
  }
}

.top {
  min-height: 200px;
  padding-bottom: 24px;
}

.bottom {
  background-color: #252525;
  padding: 12px 0;
}