.section {
  margin-bottom: 30px;
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
}


.sectionContent {
  padding: 15px;

  p {
    margin-bottom: 18px;
    font-size: 16;
    line-height: 1.33;
    font-weight: 500;
  }
}










.sectionTitle {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  background: #023365;
  color: #fff;
  padding: 8px 15px;
}