.mainContent {
  padding-bottom: 40px;
}

.sectionContent {
  min-height: 100px;
  text-align: center;
}

.qrCode {
  width: 100%;
}

.keytag {
  width: 240px;
  transform: rotate(27deg);
  margin: 40px auto 0;
  display: block
}

.noBannersText {
  color: #bfbfbf;
  font-size: 16px;
}