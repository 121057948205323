.store {
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
}

.banner {
  display: flex;
  margin-bottom: 9px;
  
  img {
    width: 100%;
  }
}
