.app-loading-element {
  position: relative;
}

.app-loading-element--loading .app-loading-element .app-loading-element__progress  {
  /* hides loader inside loader so we don't show 2 loaders one inside another */
  display: none; 
}

.app-loading-element__loader-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-loading-element__loader-container:before {
  content: '';
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-left-color: transparent;
  border-radius: 50%;
  background-color: transparent;
  animation: loader-border .75s linear infinite;
}



@keyframes loader-border {
  to { transform: rotate(360deg); }
}